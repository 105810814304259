/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/my_moon.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
        margin: "20px 0 10px 0",
      }}
    >
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author}
        style={{
          marginRight: "50px",
          marginBottom: 0,
          minWidth: 50,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <div>
        <span>
          渋谷のITベンチャーで働く <strong>{author}</strong> のテックブログ。
        </span>
        <p style={{ marginBottom: 0 }}>
          <span>Ninja250とPENTAX K3-Ⅱをお供に安らかな日々を過ごしてます。</span>
        </p>
        <p style={{ marginBottom: 0 }}>
          <a href={`https://twitter.com/${social.twitter}`} target="_blank" rel="noopener noreferrer">
            Twitterはこちら。
          </a>
          {` `}
          <a href={`https://github.com/${social.twitter}`} target="_blank" rel="noopener noreferrer">GitHubはこちら。</a>
        </p>
      </div>
    </div>
  )
}

export default Bio

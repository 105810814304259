import {useEffect} from 'react';

const UseScript = ({srcUrl, enableAsync, scriptBody, tagName, isForHead, isAppendChild}) => {

  useEffect(() => {

    const script = document.createElement(tagName ||"script");

    if (srcUrl) {
      script.src = srcUrl;
    }
    if (enableAsync) {
      script.async = true;
    }
    if (scriptBody) {
      script.innerHTML = scriptBody;
    }

    if (isForHead) {
      if (isAppendChild) {
        document.head.appendChild(script);
      } else {
        document.head.insertBefore(script, document.head.firstChild);
      }
    } else {
      if (isAppendChild) {
        document.body.appendChild(script);
      } else {
        document.body.insertBefore(script, document.body.firstChild);
      }
    }

    return () => {
      if (isForHead) {
        document.head.removeChild(script);
      } else {
        document.body.removeChild(script);
      }
    }
  }, [srcUrl, enableAsync, scriptBody, tagName, isForHead, isAppendChild]);

  return null;
};

export default UseScript;

import React from "react"
import { Link } from "gatsby"
import { Container, Columns, Column } from 'bloomer';
import UseScript from '../hooks/useScript'

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            fontFamily: `sans-serif`,
            marginBottom: "10px",
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <h3
          style={{
            fontFamily: `sans-serif`,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      )
    }

    const gtm_body = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-P9ST97H');
    `

    const noscriptBody = `
    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P9ST97H"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `

    const google_analytics_tracking_script = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'UA-150462610-1');
  `

    return (
      <Container style={{padding: "10px"}}>
        <Columns isDesktop>
          <Column isSize={{ mobile: 12, touch: 12, default: 9 }}>
            <header>{header}</header>
            <hr />
            <main>{children}</main>
            <footer>
              © {new Date().getFullYear()}, テクノロジーで現世をSurviveするブログ All Rights Reserved.
            </footer>
          </Column>
          <Column isHidden={"touch"}>
            <a className="twitter-timeline " data-lang="ja" data-width="350" data-height="700" data-theme="light" href="https://twitter.com/shoutatani?ref_src=twsrc%5Etfw">Tweets by shoutatani</a>         
          </Column>
        </Columns>
        <UseScript srcUrl="https://www.googletagmanager.com/gtag/js?id=UA-150462610-1" enableAsync={true} isForHead={true} isAppendChild={false}></UseScript>
        <UseScript scriptBody={google_analytics_tracking_script} isForHead={true} isAppendChild={false}></UseScript>
        <UseScript scriptBody={gtm_body} isForHead={true} isAppendChild={false}></UseScript>
        <UseScript scriptBody={noscriptBody} isForHead={false} isAppendChild={false} tagName="noscript"></UseScript>
      </Container>
    )
  }
}

export default Layout
